import styled from 'styled-components';
import { colors, media } from 'styles';

export const Wrapper = styled.div`
  position: relative;
  margin-top: 32px;
  max-width: 500px;

  ${media.tablet`
    max-width: 100%;
  `}
`

export const Title = styled.h2``

export const ContactGroupWrapper = styled.div`
  position: relative;
  margin-top: 32px;
  max-width: 500px;

  ${media.tablet`
    max-width: 100%;
  `}
`

export const ContactGroupTitle = styled.h6`
  ${media.tablet`
    margin-top: 10px;
  `}
`

export const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`

export const Item = styled.li`
  display: flex;
  flex-direction: row;
  margin-top: 20px;

  ${media.tablet`
    flex-direction: column;
  `}
`

export const ItemName = styled.div`
  flex: 1;

  ${media.tablet`
    flex: none;
  `}
`

export const ItemLink = styled.a`
  font-size: 18px;
  flex: 1;
  color: ${colors.DARK_GREY};

  ${media.tablet`
    flex: none;
  `}
`
