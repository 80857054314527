const CONTACT_ITEM_TYPES = {
  EMAIL: 'email',
  PHONE: 'phone'
};

export default {
  CONTACTS_TITLE: 'Contacts',
  CONTACT_ITEM_TYPES,
  CONTACTS: [{ 
    name: 'Main Contractor', 
    items: [{
      type: CONTACT_ITEM_TYPES.EMAIL,
      name: 'O’Keefe Construction',
      email: 'saguy.chetrit@okeefe.co.uk'
    }]
  }, { 
    name: 'Emergency / Out of Hours Security', 
    items: [{
      type: CONTACT_ITEM_TYPES.PHONE,
      name: 'Contact Phone #1',
      phone: '0345 888 8333'
    }, {
      type: CONTACT_ITEM_TYPES.PHONE,
      name: 'Contact Phone #2',
      phone: '0345 853 2020'
    }]
  }]
}
