import { getTexts } from '../../utilities/translations';

// NOTE: This page doesn't have translations so we return the same value for texts
// in both `en` and `zh` properties of the module return value.
const textsWithNoTranslations = {
  subheading: `Brill Place Tower`,
  heading: `Brill Place Tower Construction Works.`,
  description1: `Brill Place Tower (also known as Grand Central Apartments) is an exciting new 22 storey development of 68 residential apartments. The Tower will also accommodate a single storey basement,  ground floor residential lobby and public café with views over the Purchase Street Open Space.`,
  description2: `The design of the Tower and the construction works will be undertaken in accordance with the approved Construction Management Plan (CMP) and coordinated with London Borough of Camden’s public realm works planned for the Purchase Street Open Space.`,
  description3: `LBS and their Contractors have significant experience in delivering exceptional developments with care and attention and are committed to working closely with all residents and businesses in Somers Town to minimise disruption during the construction works.`,
  cta: `See construction updates`
};

export default getTexts({
  'en': { ...textsWithNoTranslations },
  'zh': { ...textsWithNoTranslations }
});
